import { MessageBarIntent } from "@fluentui/react-components";

export type MessageInfo = {
  intent: MessageBarIntent
  title?: string
  description: string
}

export const MessageBarIntentMapping: { [key: string]: MessageBarIntent } = {
  Alert: 'error',
  Warning : 'warning',
  Notification : 'info'
}

// TODO: refactor to React Context
export class MessageContext {
  static infoboardMessages: MessageInfo[] = [];
  static loaded: boolean = false;
  static setInfoboardMessages = (messages: MessageInfo[]) => {
    this.infoboardMessages = messages;
    this.loaded = true;
  };
}
