import axios from "axios";
import { Auth } from "../utils/auth";
import { IdNameDto } from "../models";
import config from "../config";
import { TechnicalAccountManager } from "../models/CustomerManagement";
import { useQuery, UseQueryResult } from "react-query";

export const loadTenants = async () => {
  const token = await Auth.getToken([]);
  return await axios.get<IdNameDto[]>(`${config.infoboardBackendUrl}/tenants/all`, {
    headers: {
      Authorization: `Bearer ${token.token}`
    }
  })
};

export const loadInfoboard = async() => {
  const token = await Auth.getToken([]);
  return await axios.get(`${config.infoboardBackendUrl}/messages/infoboard`, {
    headers: {
      Authorization: `Bearer ${token.token}`
    }
  });
}

export const getTechnicalAccountManager = async() => {
  const token = await Auth.getToken([]);
  return await axios.get<TechnicalAccountManager>(`${config.infoboardBackendUrl}/tenants/technicalAccount`, {
    headers: {
      Authorization: `Bearer ${token.token}`
    }
  });
}

export const useGetTechnicalAccountManager = (enabled?: boolean) : UseQueryResult<TechnicalAccountManager, unknown> => {
  return useQuery({
    queryKey: ["technicalAccountManager"],
    queryFn: async () => {
      const token = await Auth.getToken([]);
      const response = await axios.get<TechnicalAccountManager>(`${config.infoboardBackendUrl}/tenants/technicalAccount`, {
        headers: {
          Authorization: `Bearer ${token.token}`
        }
      });
      return response.data;
    },
    retry: false,
    enabled: enabled,
    staleTime: 1000
  });
}
