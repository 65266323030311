import {
  makeStyles,
  tokens,
  Text, 
  Persona,
  Popover,
  PopoverTrigger,
  PopoverSurface,
  Tooltip,
  PresenceBadgeStatus,
  Button,
  Skeleton,
  SkeletonItem
} from "@fluentui/react-components";
import { MailRegular, ChatRegular, CallRegular } from "@fluentui/react-icons";
import { useGetTechnicalAccountManager } from "../apis/CustomerManagementApi";
import { splitTitleCase } from "../utils/StringConverter";
import { CopyButton } from "./buttons";
import { app } from "@microsoft/teams-js";

const useStyles = makeStyles({
  statusBar: {
    backgroundColor: tokens.colorBrandStroke2,
    display: "flex",
    padding: "10px",
    gap: "10px"
  },
  skeletonRow: {
    alignItems: "center",
    display: "grid",
    gap: "10px",
    gridTemplateColumns: "min-content 80%",
    width: "160px"
  },
  miniPesona: {
    cursor: "pointer"
  },
  persona: {
    padding: "16px",
    borderBottom: "1px solid rgb(243, 242, 241)"
  },
  popover: {
    display: "flex",
    flexDirection: "column",
    padding: "0px"
  },
  contactsBlock: {
    padding: "10px 0px"
  },
  contactRow: {
    display: "flex", 
    flexDirection: "row",
    paddingRight: "48px",
    ":hover": {
      backgroundColor: tokens.colorNeutralBackground1Hover,
      paddingRight: "16px",
    },
    ":hover #copyButton": {
      display: "block"
    }
  },
  contactButton: {
    width: "100%",
    justifyContent: "left",
    paddingLeft: "16px"
  },
  buttonText: {
    color: tokens.colorBrandStroke1
  },
  copyButton: {
    display: "none"
  }
});

const mapStatus = (status: string): PresenceBadgeStatus => {
  const statusMap = {
      'Available': 'available',
      'AvailableIdle': 'available',
      'Away': 'away',
      'BeRightBack': 'away',
      'Busy': 'busy',
      'BusyIdle': 'busy',
      'DoNotDisturb': 'do-not-disturb',
      'Offline': 'offline',
      'PresenceUnknown': 'unknown'
  };

  return statusMap[status] || 'blocked';
}

export const TechnicalAccountManagerProfile = () => {
  const styles = useStyles();
  const { data: technicalManager } = useGetTechnicalAccountManager();

  return (
    <div className={styles.statusBar}>
      <Text size={400} weight="semibold">Your technical account manager:</Text>
      {!technicalManager ? 
      <Skeleton className={styles.skeletonRow}>
        <SkeletonItem shape="circle" size={20} />
        <SkeletonItem size={16} />
      </Skeleton> :
      <Popover positioning="below-end">
        <PopoverTrigger disableButtonEnhancement>
          <Tooltip content={splitTitleCase(technicalManager.availability)} relationship="label">
          <Persona className={styles.miniPesona}
            name={technicalManager.displayName}
            presence={{ status: mapStatus(technicalManager.availability) }}
            size="extra-small"
            avatar={{
              image: {
                src: `data:image/png;base64, ${technicalManager.profilePhoto}`
              }
            }}/>
          </Tooltip>
        </PopoverTrigger>
        <PopoverSurface className={styles.popover}>
          <Persona className={styles.persona}
            name={technicalManager.displayName}
            secondaryText={technicalManager.jobTitle || 'Not specified'}
            tertiaryText={technicalManager.department || 'Not specified'}
            presence={{ status: mapStatus(technicalManager.availability) }}
            size="huge"
            avatar={{
              image: {
                src: `data:image/png;base64, ${technicalManager.profilePhoto}`
              }
            }}/>
          <div className={styles.contactsBlock}>
            <div className={styles.contactRow}>
              <Button className={styles.contactButton}
                icon={<MailRegular />} 
                appearance="subtle"
                as="a" href={`mailto:${technicalManager.email}`}
              >
                <Text className={styles.buttonText}>
                  {technicalManager.email}
                </Text>
              </Button>
              <CopyButton id="copyButton" className={styles.copyButton}
                onClick={() => {
                  navigator.clipboard.writeText(technicalManager.email)
                }}/>
            </div>
            <div className={styles.contactRow}>
              <Button className={styles.contactButton}
                icon={<ChatRegular />} 
                appearance="subtle"
                onClick={() => app.openLink(`https://teams.microsoft.com/l/chat/0/0?users=${technicalManager.email}`)}
              >
                <Text className={styles.buttonText}>
                  {technicalManager.email}
                </Text>
              </Button>
              <CopyButton id="copyButton" className={styles.copyButton}
                onClick={() => {
                  navigator.clipboard.writeText(technicalManager.email)
                }}/>
            </div>
            {technicalManager.mobilePhone && <div className={styles.contactRow}>
              <Button className={styles.contactButton}
                icon={<CallRegular />}
                appearance="subtle"
                as="a" href={`tel:${technicalManager.mobilePhone}`}
              >
                <Text className={styles.buttonText}>
                  {technicalManager.mobilePhone}
                </Text>
              </Button>
              <CopyButton id="copyButton" className={styles.copyButton}
                onClick={() => {
                  navigator.clipboard.writeText(technicalManager.mobilePhone)
                }}/>
            </div>}
          </div>
        </PopoverSurface>
      </Popover>}
    </div>
  );
};