import {
  makeStyles,
  shorthands,
  tokens,
  Text,
  Caption1, 
  Card,
  CardHeader,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  mergeClasses,
  DialogTrigger,
  DialogTitle,
  Button,
  Spinner
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useGetTechnicalAccountManager } from "../../apis/CustomerManagementApi";
import { getTenantName } from "../../apis/GraphApi";

const useStyles = makeStyles({
  card: {
    height: "fit-content"
  },
  caption: {
    color: tokens.colorNeutralForeground3,
  },
  logo: {
    ...shorthands.borderRadius("4px"),
    width: "48px",
    height: "48px",
  },
  text: {
    ...shorthands.margin(0),
  },
  dialogText: {
    textAlign: "justify"
  }
});

export const DigitalTransformationAppCard = (props: { className?: string, img: string }) => {
  const styles = useStyles();
  const [companyName, setCompanyName] = useState<string>("");
  const { data: technicalManager } = useGetTechnicalAccountManager();

  useEffect(() => {
    getTenantName()
      .then((name) => setCompanyName(name))
      .catch((error) => console.error(error));
  }, []);

  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <Card
          className={mergeClasses(props.className, styles.card)}
        >
          <CardHeader
            image={
              <img
                className={styles.logo}
                src={props.img}
                alt="App logo"
              />
            }
            header={<Text weight="semibold">Digital Transformation Call</Text>}
            description={<Caption1 className={styles.caption}>IT Partner LLC</Caption1>}
          />

          <p className={styles.text}>
            Transform Your IT Strategy with Monthly Expert Guidance!
          </p>
        </Card>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle
            action={
              <DialogTrigger action="close">
                <Button
                  appearance="subtle"
                  aria-label="close"
                  icon={<Dismiss24Regular />}
                />
              </DialogTrigger>
            }
          >
            Service Description
          </DialogTitle>
          <DialogContent className={styles.dialogText}>
            {(!companyName || !technicalManager) ? <Spinner size="large"/> :
            <>
              <p>Our <strong>Monthly Digital Transformation call</strong> service is designed to help our Clients set, define, and achieve their strategic IT goals, like transitioning from on-prem AD to Azure AD, implementing Microsoft Intune for Autopilot and Zero-touch Deployment, migrating to Teams Phone System, etc. This service includes a monthly call with a Microsoft 365 expert to discuss progress, identify areas for improvement, and provide guidance on how to leverage the full capabilities of Microsoft 365 to achieve those goals. We will also provide updates on the latest advancements in Microsoft 365 and provide guidance on how to leverage those features to improve productivity, enhance security, and streamline processes. We will also discuss plans for the next month and create and assign tasks for all involved parties.</p>
              
              <p>In addition, this includes customized solutions tailored to the specific needs of each organization. We will work closely with our clients to identify areas where customized solutions can help achieve strategic goals more efficiently and effectively.</p>
              
              <p>The service can also provide numerous benefits to organizations, including staying up-to-date with the latest technology advancements, improving productivity, enhancing security, increasing efficiency, and reducing costs. With regular check-ins and guidance from our Microsoft 365 experts, organizations can achieve their strategic goals and position themselves for long-term success.</p>

              <p><strong>Ready to start your transformation? <a href={`mailto:${technicalManager?.email}?subject=Digital%20Transformation%20Call%20Demo%20Inquiry&body=Hello%20${technicalManager?.displayName},%0D%0A%0D%0AWe%20are%20${companyName}%20and%20are%20interested%20in%20learning%20more%20about%20the%20Monthly%20Digital%20Transformation%20Call.%0D%0A%0D%0APlease,%20provide%20us%20with%20additional%20details%20or%20schedule%20a%20demo.`}>Contact your Technical Account Manager</a> to schedule your call!</strong></p>
            </>}
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};