import { Button, ButtonProps } from "@fluentui/react-components";
import { CopyRegular } from '@fluentui/react-icons';

export const CopyButton = (props: ButtonProps) => {
  return (
    <Button
      appearance="subtle"
      icon={<CopyRegular/>}
      {...props}
      />
  );
}