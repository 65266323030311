import { Button, ButtonProps, Tooltip } from "@fluentui/react-components";
import { Home20Regular } from '@fluentui/react-icons';
import { useNavigate } from "react-router-dom";

export const HomeButton = (props: ButtonProps) => {
  const navigate = useNavigate();
    
  return (
    <Tooltip content="Home" relationship="label">
      <Button
        icon={<Home20Regular/>}
        onClick={() => navigate('/tab')}
        {...props}
        />
    </Tooltip>
  );
}