import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MessageBarGroup, makeStyles, shorthands } from "@fluentui/react-components";
import { app } from "@microsoft/teams-js";
import { TeamsFxContext } from "../Context";
import { MessageInfo } from "../MessageContext";
import {
  Communication,
  Infoboard,
  NotificationBar,
  Services,
  TechnicalAccountManagerProfile
} from "../components";
import { useQuery, useQueryClient } from "react-query";

const useStyles = makeStyles({
  hub: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: "100%",
    "@media (max-width: 450px)": {
      flexDirection: "column",
    }
  },
  content: {
    width: "100%"
  },
  globalAdminMessages: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      ...shorthands.margin("3px")
    }
  }
});

let redirected = false;

export default function Tab() {
  const { themeString } = useContext(TeamsFxContext);
  const { data: message } = useQuery<MessageInfo | null>({ queryKey: 'notificationMessage' })
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  app.getContext().then((context: app.Context) => {
    let subPageId = context.page.subPageId;
    if (subPageId && !redirected) {
      navigate(`../${subPageId}`);
      redirected = true;
    }
  });

  const onDismiss = () => {
    queryClient.setQueryData('notificationMessage', null);
  }

  const styles = useStyles();
  return (
    <div className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}>
      <div className={styles.hub}>
        <Communication />
        <div className={styles.content}>
          <TechnicalAccountManagerProfile />
          <MessageBarGroup>
            {message && <NotificationBar {...message} onDismiss={onDismiss} />}
          </MessageBarGroup>
          <Infoboard />
          <Services />
        </div>
      </div>
    </div>
  );
}
