import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
  Text,
  Textarea,
  TextareaOnChangeData,
  makeStyles
} from "@fluentui/react-components";
import { useState } from "react";

const useStyles = makeStyles({
  title: {
    textAlign: "center"
  },
  content: {
    display: "flex",
    flexDirection: "column"
  },
  textHeader: {
    display: "flex",
    paddingTop: "1em",
    justifyContent: "space-between"
  }
});

export type CommentDialogProps = {
  open: boolean;
  isSending: boolean;
  onCancelClick: () => void;
  onProceedClick: (comment: string) => void;
};

export const CommentDialog = (props: CommentDialogProps) => {
  const styles = useStyles();
  const [comment, setComment] = useState("");
  
  const onCommentChange = (_: any, data: TextareaOnChangeData) => {
    if (data.value.length <= 500)
      setComment(data.value);
  };

  const highlightCounter = comment.length === 500;
  return (
    <Dialog open={props.open}>
      <DialogSurface>
        <DialogBody>
        <DialogTitle className={styles.title}>
            Decision Making
          </DialogTitle>
          <DialogContent className={styles.content}>
            <Text>
              The final decision (Accept, Decline) cannot be changed.
              If necessary, you can accompany your decision with a comment. Are you sure you want to proceed?
            </Text>
            <div className={styles.textHeader}>
              <Text>
                Comment
              </Text>
              <Text style={highlightCounter ? {color: "red"} : {}}>
                {comment.length}/500
              </Text>
            </div>
            <Textarea
              appearance="outline"
              placeholder="type here..."
              onChange={onCommentChange}
              value={comment}
              rows={6}
            />
          </DialogContent>
          
          <DialogActions>
            <Button appearance="secondary" onClick={props.onCancelClick}>Cancel</Button>
            <Button appearance="primary"
              disabled={props.isSending}
              icon={props.isSending ? <Spinner size="tiny" /> : null}
              onClick={() => props.onProceedClick(comment)}>
              Proceed
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}