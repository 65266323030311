import {
  makeStyles,
  shorthands,
  tokens,
  Text,
  Caption1,
  mergeClasses,
  CardProps,
  Card,
  CardHeader,
  CardHeaderProps
} from "@fluentui/react-components";
import { OpenRegular } from "@fluentui/react-icons";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useIsGlobalAdmin } from "../../apis/GraphApi";

const useStyles = makeStyles({
  card: {
    height: "fit-content"
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  logo: {
    borderRadius: "4px",
    width: "48px",
    height: "48px",
  },

  text: {
    ...shorthands.margin(0),
  },

  header: {
    display: "flex", 
    alignItems: "center", 
    justifyContent: "space-between",
    width: "100%"
  }
});

export interface AppCardProps extends CardProps {
  title: string,
  description: string,
  img: string,
  action?: CardHeaderProps["action"],
  href?: string,
  page?: string
  isForAdmin?: boolean
}

export const AppCard = ({ className, ...props }: AppCardProps) => {
  const navigate = useNavigate();
  const styles = useStyles();
  const { data: isGlobalAdmin, isFetching } = useIsGlobalAdmin();
  const queryClient = useQueryClient();

  const onClick = () => {
    if (props.isForAdmin && !isGlobalAdmin) {
      queryClient.setQueryData('notificationMessage', {
        intent: "error",
        description: "This service is available only to users with the Global Administrator role assigned. Please contact your company's Administrator"
      });
      return;
    }
    if (props.page) {
      navigate(props.page);
    }
    else if (props.href) {
      window.open(props.href, "_blank");
    }
  }

  if (isFetching) {
    return null;
  }

  return (
    <Card
      {...props}
      className={mergeClasses(className, styles.card)}
      onClick={onClick}
    >
      <CardHeader
        image={
          <img
            className={styles.logo}
            src={props.img}
            alt="App logo"
          />
        }
        header={
          <div
            className={styles.header}
          >
            <Text weight="semibold">{props.title}</Text>
            {props.href && <OpenRegular />}
          </div>
        }
        description={<Caption1 className={styles.caption}>IT Partner LLC</Caption1>}
        action={props.action}
      />

      <p className={styles.text}>
        {props.description}
      </p>
    </Card>
  );
};