import { useState } from "react";
import {
  Button,
  Spinner,
  Textarea,
  TextareaOnChangeData,
  makeStyles,
  shorthands,
  tokens
} from "@fluentui/react-components";
import { useNavigate, useParams } from "react-router-dom";
import { MessageInfo } from "../../MessageContext";
import { sendOffer } from "../../apis/OffersApi";
import { PageLayout } from "../../components";
import { SendOfferDialog } from "../../components/dialogs";
import logo from "../../images/itp_logo.png";
import { useQueryClient } from "react-query";

const useStyles = makeStyles({
  body: {
    backgroundColor: tokens.colorNeutralBackground1,
    width: "560px",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.padding("1em", "1em")
  },
  description: {
    textAlign: "center",
    ...shorthands.margin(0, 0, "1em")
  },
  cent:{
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  input: {
    width: "100%"
  },
  cancel: {
    marginRight: "2em",
    marginTop: "1em",
    color: "#4F52B2",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    ...shorthands.margin("1em", 0)
  },
  button: {
    marginTop: "1em",
  }
});

export const PrepareOfferPage = () => {
  const offerId = useParams().id;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [message, setMessage] = useState<MessageInfo>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [validated, setValidated] = useState(true);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [input, setInput] = useState(
`Product name:
Product type:
Product price:
Comment:`);

  const onChange = (_: any, data: TextareaOnChangeData) => {
    setInput(data.value);
    setValidated(true);
  };

  const onSubmitClick = () => {
    if (input.trim().length === 0) {
      setValidated(false);
      setMessage({
        intent: "error",
        title: "Validation error",
        description: "Fill out the form"
      });
    } 
    else {
      setDialogOpen(true);
    }
  }

  const onConfirmClick = () => {
    setLoading(true);
    setDialogOpen(false);
    sendOffer(offerId, input)
      .then(() => {
        setLoading(false);
        queryClient.setQueryData('notificationMessage', {
          intent: "success",
          description: "Your offer has been submitted"
        });
        navigate("/tab");
      })
      .catch((e: Error) => {
        setLoading(false);
        setDialogOpen(false);
        setMessage({
          intent: "error",
          title: "Error",
          description: e.message
        });
      });
  };

  const onCancelClick = () => navigate("/tab");

  const onDismiss = () => setMessage(null);

  const styles = useStyles();

  return (
    <PageLayout message={message} onDismiss={onDismiss}>
      <div className={styles.body}>
        <img src={logo} className={styles.cent} height="96px" width="96px" alt="ITP logo" />
        <h3 className={styles.description}>License Assistant - Prepare an offer</h3>
        <p className={styles.description}>Please review the request and make your best offer</p>
        <Textarea
          style={validated ? {} : {borderColor: "red"}}
          className={styles.input}
          appearance="outline"
          resize="vertical"
          onChange={onChange}
          rows={8}
          defaultValue={input}
        />
        <div className={styles.buttons}>
          <Button className={styles.cancel} onClick={onCancelClick} size="large">
            Cancel
          </Button>
          <Button 
            disabled={isLoading}
            icon={isLoading ? <Spinner size="tiny" /> : null}
            className={styles.button} 
            onClick={onSubmitClick} 
            size="large" 
            appearance="primary">
              Send an offer
          </Button>
        </div>
      </div>
      <SendOfferDialog
        open={dialogOpen}
        onDeclineClick={() => setDialogOpen(false)}
        onConfirmClick={onConfirmClick}
      />
    </PageLayout>
  );
}