import { makeStyles, shorthands } from "@fluentui/react-components";
import { AppCard, LicenseAssistantAppCard, DigitalTransformationAppCard } from "./cards";
import toLogo from "../images/to_logo.png";
import tmLogo from "../images/tm_logo.png";
import laLogo from "../images/la_logo.png";
import lcLogo from "../images/lc_logo.png";
import spLogo from "../images/sp_logo.svg";
import dt_logo from "../images/dt_logo.png";

const useStyles = makeStyles({
  box: {
    ...shorthands.padding("0px", "15px", "15px")
  },
  columnBox: {
    display: "flex",
    flexDirection: "column"
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "49% auto",
    columnGap: "15px",
    rowGap: "15px",
    maxWidth: "1080px",
    "@media (max-width: 720px)": {
      gridTemplateColumns: "auto"
    }
  }
})

export const Services = () => {
  const styles = useStyles();

  return (
    <div className={styles.box}>
      <div className={styles.columnBox}>
        <h2>Admin apps</h2>
        <div className={styles.gridContainer}>
          <AppCard
            title="Tenant Optimizer"
            description="Boost your tenant's efficiency: Register, Analyze, Optimize!"
            img = {toLogo}
            page="../tenant-optimizer" />
          <AppCard title="Tenant Pre-Migration Assistant"
            description="Explore your tenant-to-tenant migration project"
            img = {tmLogo}
            page="../migration-report" />
          <AppCard title="Self-Service Portal"
            description="Your one-stop portal for Microsoft products management"
            img = {spLogo}
            href="https://portal.o365hq.com/#/customer/products"
            isForAdmin={true}/>
          <DigitalTransformationAppCard
            img={dt_logo}/>
        </div>
        <h2>User apps</h2>
        <div className={styles.gridContainer}>
          <LicenseAssistantAppCard
            img = {laLogo} />
          <AppCard title="License Comparison"
            description="Explore, Compare, Empower: Uncover Microsoft Product Insights with Ease!"
            img = {lcLogo}
            page="../license-comparison" />
        </div>
      </div>
    </div>
  );
}
